"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommutingTripService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class CommutingTripService {
  static async createCommutingTrip(_ref) {
    let {
      employeeId: employee,
      title,
      home,
      work,
      bicyclePortions,
      isWorkToHome: display_reverse_order
    } = _ref;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.post('v1', `/commuting_trips`, [], [], JSON.stringify({
        employee,
        title,
        home: home.id,
        work: work.id,
        bicycle_portions: bicyclePortions.map(_ref2 => {
          let {
            start,
            end,
            order: commuting_trip_order
          } = _ref2;
          return {
            user_place_start: start.id,
            user_place_end: end.id,
            commuting_trip_order
          };
        }),
        display_reverse_order
      }));
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][createCommutingTrip]', err);
      throw new Error('commuting trip not created');
    }
  }
  static async updateCommutingTrip(id, _ref3) {
    let {
      employeeId: employee,
      title,
      home,
      work,
      bicyclePortions
    } = _ref3;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.patch('v1', `/commuting_trips/${id}`, [], [], JSON.stringify({
        employee,
        title,
        home: home.id,
        work: work.id,
        bicycle_portions: bicyclePortions.map(_ref4 => {
          let {
            start,
            end,
            order: commuting_trip_order
          } = _ref4;
          return {
            user_place_start: start.id,
            user_place_end: end.id,
            commuting_trip_order
          };
        })
      }));
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][updateCommutingTrip]', err);
      throw new Error('commuting trip not updated');
    }
  }
  static async getCommutingTrips(_ref5) {
    let {
      employeeId
    } = _ref5;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const {
        results
      } = await _http.HttpService.get('v1', `/commuting_trips`, [{
        key: 'page',
        value: 1
      }, {
        key: 'page_size',
        value: 100
      }, {
        key: 'employee_id',
        value: employeeId
      }, {
        key: 'enabled',
        value: true
      }]);
      return results.map(parseCommutingTrip);
    } catch (err) {
      console.error('[CommutingTripService][getCommutingTrips]', err);
      throw err;
    }
  }
  static async getCommutingTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.get('v1', `/commuting_trips/${id}`);
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][getCommutingTrip]', err);
      throw err;
    }
  }
  static async removeCommutingTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      await _http.HttpService.delete('v1', `/commuting_trips/${id}`);
      return true;
    } catch (err) {
      console.error('[CommutingTripService][removeCommutingTrip]', err);
      throw err;
    }
  }
}
exports.CommutingTripService = CommutingTripService;
function parseCommutingTrip(_ref6) {
  let {
    id,
    title,
    home: homeId,
    home_address,
    home_point,
    work: workId,
    work_address,
    work_point,
    bicycle_portions,
    display_reverse_order: isWorkToHome
  } = _ref6;
  const bicyclePortions = (bicycle_portions || []).sort((a, b) => a.commuting_trip_order - b.commuting_trip_order);
  if (isWorkToHome) bicyclePortions.reverse();
  const start = new _models.Place(homeId, home_point, {
    primaryText: home_address
  });
  const end = new _models.Place(workId, work_point, {
    primaryText: work_address
  });
  return new _models.CommutingTrip(id, title || `Trajet vélotaf ${id}`, isWorkToHome ? end : start, isWorkToHome ? start : end, bicyclePortions.map(_ref7 => {
    let {
      id,
      user_place_start,
      geo_start,
      geo_start_title,
      user_place_end,
      geo_end,
      geo_end_title,
      geometry_start_end,
      geometry_end_start,
      distance_in_meters_start_end,
      distance_in_meters_end_start
    } = _ref7;
    const portionStart = new _models.UserPlace(user_place_start, 'other', geo_start_title, geo_start, geo_start_title, (0, _moment.default)());
    const portionEnd = new _models.UserPlace(user_place_end, 'other', geo_end_title, geo_end, geo_end_title, (0, _moment.default)());
    return isWorkToHome ? {
      id,
      start: portionEnd,
      end: portionStart,
      geometry: geometry_end_start,
      distance: distance_in_meters_end_start
    } : {
      id,
      start: portionStart,
      end: portionEnd,
      geometry: geometry_start_end,
      distance: distance_in_meters_start_end
    };
  }), isWorkToHome);
}