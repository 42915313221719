"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommutingTrip = void 0;
class CommutingTrip {
  constructor(id, title, home, work, bicyclePortions, isWorkToHome) {
    this.id = id;
    this.title = title;
    this.home = home;
    this.work = work;
    this.bicyclePortions = bicyclePortions;
    this.isWorkToHome = isWorkToHome;
  }
  get bicycleDistance() {
    return this.bicyclePortions.reduce((res, _ref) => {
      let {
        distance
      } = _ref;
      return res + distance;
    }, 0);
  }
}
exports.CommutingTrip = CommutingTrip;